import axios from 'axios';
import React, { useEffect } from 'react'

const NewTest = () => {
  useEffect(async()=>{
    const response = await axios.get('http://localhost:4000/api/testing',  {
        headers: {  
            Authorization: `Bearer ${localStorage.getItem('token')}`, // Include the token in the header
        }        });

    console.log(response,'responsesadfsadsaf  ')

},[])
  return (
    <div>
      
    </div>
  )
}

export default NewTest

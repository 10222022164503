import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './css/Register.css';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
function Register() {
  const [formData, setFormData] = useState({
    name: '',
    userId: '',
    phoneNumber: '',
    password: '',
    role: 'user',
  });
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
const handelPhone=(phone)=>{
  setFormData({ ...formData, ['phoneNumber']: phone });

}
  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(formData.phoneNumber)
    try {
      await axios.post('https://backenddoctorchat.rouad-althaqa.com/api/register', formData);
      alert('Registration successful!');
      navigate('/verify', { state: { ...formData } });
    } catch (err) {
      setError(err.response?.data?.message || 'An error occurred');
    }
  };

  return (
<div className='body'>
<div className="register-container">
      <div className="register-card">
        <h2 className="register-title">Register</h2>
        {error && <p className="error-message">{error}</p>}
        <form className="register-form" onSubmit={handleSubmit}>
          <input
            className="register-input"
            name="name"
            placeholder="Name"
            value={formData.name}
            onChange={handleChange}
            required
          />
          <input
            className="register-input"
            name="userId"
            placeholder="User ID"
            value={formData.userId}
            onChange={handleChange}
            required
            maxLength={10}
            minLength={10}
          />
          {/* <input
            className="register-input"
            name="phoneNumber"
            placeholder="Phone Number"
            value={formData.phoneNumber}
            onChange={handleChange}
            required
          /> */}

    <div

    >
    <PhoneInput
    onlyCountries={['sa']}
  country={'sa'}
  value={formData.phoneNumber}
  inputProps={{
    name: 'phone',
    required: true,
    autoFocus: true,
    autoFormat:true
  }}

  onChange={phone => handelPhone(phone)}
  inputStyle={{width:"100%"}}
/>
    </div>
          <input
            className="register-input"
            name="password"
            type="password"
            placeholder="Password"
            value={formData.password}
            onChange={handleChange}
            required
            style={{marginTop:"15px"}}
          />
          <select
            className="register-select"
            name="role"
            value={formData.role}
            onChange={handleChange}
          >
            <option value="user">User</option>
            <option value="admin">Admin</option>
          </select>
          <button className="register-button" type="submit">
            Register
          </button>
        </form>
        <div className="back-to-login">
          <p>Already have an account? <a href="/">Login</a></p>
        </div>
      </div>
    </div>
</div>
  );
}

export default Register;


import React, { useState, useRef, useEffect } from "react";
import "./../css/ChatWithVoice.css";
import { socket } from "../../../socket";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import Logout from "./../../../assets/Logout.png";
import { useNavigate } from "react-router-dom";


const decodeToken = (token) => {
    try {
        const decoded = jwtDecode(token); // Decode the token
        console.log('Decoded Token:', decoded);
        return decoded;
    } catch (error) {
        console.error('Invalid token:', error.message);
        return null;
    }
};
const UserChat = () => {
  const [messages, setMessages] = useState([]);
  const [currentMessage, setCurrentMessage] = useState("");
  const [activeUser, setActiveUser] = useState("");
  const recognitionRef = useRef(null);
  const mediaRecorderRef = useRef(null);
  const messagesEndRef = useRef(null);
  const [isRecording, setIsRecording] = useState(false);
  const [users, setUser] = useState([]);
  const [roomId, setRoomId] = useState();
  const [userId, setUserId] = useState();
  const [type, setTypeing] = useState("");
  const [Doctor, setDoctor] = useState("");

  const [isUploading, setIsUploading] = useState(false);
  const navigate = useNavigate();

  // const users = ["John Doe", "Jane Smith", "Alice Johnson"];

  // Initialize Speech Recognition
  const startVoiceRecognition = () => {
    if (!("webkitSpeechRecognition" in window) && !("SpeechRecognition" in window)) {
      alert("Speech Recognition API is not supported in this browser.");
      return;
    }

    const SpeechRecognition =
      window.SpeechRecognition || window.webkitSpeechRecognition;
    const recognition = new SpeechRecognition();
    recognition.lang = "en-US";

    recognition.onstart = () => console.log("Voice recognition started...");
    recognition.onresult = (event) => {
      const transcript = event.results[0][0].transcript;
      setCurrentMessage(transcript);
    };
    recognition.onerror = (event) => {
      console.error("Speech Recognition Error:", event.error);
    };

    recognitionRef.current = recognition;
    recognition.start();
  };
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };
  
  useEffect(() => {
    scrollToBottom();
  }, [messages]);
    // Start Voice Recording
  const startVoiceRecording = async () => {
    if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
      alert("Your browser does not support audio recording.");
      return;
    }

    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      const mediaRecorder = new MediaRecorder(stream);
      const audioChunks = [];

      mediaRecorder.ondataavailable = (event) => {
        audioChunks.push(event.data);
      };

      mediaRecorder.onstop = async () => {
        const audioBlob = new Blob(audioChunks, { type: "audio/wav" });
        const formData = new FormData();
        formData.append("file", audioBlob);
      
        try {
          console.log("Uploading audio blob:", audioBlob);
      
          const response = await axios.post(
            "https://backenddoctorchat.rouad-althaqa.com/api/upload",
            formData,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                "Content-Type": "multipart/form-data", // Ensure the correct header is set
              },
            }
          );
      
          console.log("Server response:", response.data);
          const { filePath } = response.data; // Extract the uploaded file path
      
          const token = localStorage.getItem("token");
          const userData = decodeToken(token);
          const id = userData.userId;
      
          // Emit the file path to the server after successful upload
          socket.emit("sendPrivateMessage", {
            roomId,
            sender: id,
            receiver: activeUser,
            type: "voice",
            media: filePath,
            doctorId:activeUser,userId:id
          });
        socket.emit("updateUser", {userId:activeUser})
          console.log("Voice message sent with path:", filePath);
        } catch (error) {
          console.error("Error uploading audio:", error);
        }
      };

      mediaRecorder.start();
      setIsRecording(true);
      mediaRecorderRef.current = mediaRecorder;
    } catch (error) {
      console.error("Error accessing microphone:", error);
    }
  };

  // Stop Voice Recording
  const stopVoiceRecording = () => {
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();
      setIsRecording(false);
    }
  };

  // Handle message sending
  const handleSend = (e) => {
    e.preventDefault();
    const token=localStorage.getItem('token')

    const userData = decodeToken(token);
    const id=userData.userId
    if (currentMessage.trim()) {
      // setMessages((prevMessages) => [
      //   ...prevMessages,
      //   { type: "text", sender:id, recipient: activeUser, media: currentMessage },
      // ]);

      socket.emit("sendPrivateMessage",{ roomId:roomId, sender:id, receiver:activeUser,doctorId:activeUser,userId:id, type:"text", media:currentMessage  })
      socket.emit("updateUser", {userId:activeUser})

      setCurrentMessage("");
    }
  };
  const fetchUserData = async (token) => {
    try {
        const response = await axios.get('https://backenddoctorchat.rouad-althaqa.com/api/users', {
            headers: {
                Authorization: `Bearer ${token}`, // Include the token in the header
            }        });
            setUser(response.data.users)
            setActiveUser(response.data.users[0].userId)
            setDoctor(response.data.users[0].name)
            const tokens=localStorage.getItem('token')

              const userData = decodeToken(tokens);
              const id=userData.userId
              const roomId = [id, response.data.users[0].userId].sort().join("-");  
            setRoomId(roomId)
      const responses = await axios.get(`https://backenddoctorchat.rouad-althaqa.com/api/chat/${response.data.users[0].userId}`, {
              headers: {
                  Authorization: `Bearer ${tokens}`, // Include the token in the header
              }        });
              setMessages(responses.data.chat);

            console.log(roomId,responses,'responses','roomId')
            socket.emit("joinPrivateRoom", { user1: id, user2:  response.data.users[0].userId });




    } catch (error) {
        console.error('Error fetching user data:', error.response?.data || error.message);
    }
};
useEffect(()=>{
  fetchUserData(localStorage.getItem("token"))
  const token=localStorage.getItem('token')

  const userData = decodeToken(token);
  const id=userData.userId
  setUserId(id)
},[])
useEffect(() => {
  // Set up the socket event listener ONLY ONCE
  const handleReceiveMessage = (messageData) => {
    console.log(messageData);
    setTypeing('')
    setMessages((prevMessages) => [...prevMessages, messageData]);
  };

  socket.on("receivePrivateMessage", handleReceiveMessage);

  // Cleanup the listener on component unmount to prevent duplicates
  return () => {
    socket.off("receivePrivateMessage", handleReceiveMessage);
  };
}, [socket]);
  useEffect(()=>{
    socket.on("error", (errors) => {
      console.log(errors,'error')
      // setMessages((prevMessages) => [...prevMessages, messageData]);
    });

  },[])
  
const ChosesactiveUser=async(user,name)=>{
  const token=localStorage.getItem('token')
    if (user.trim()) {
      const userData = decodeToken(token);
      setDoctor(name)
      const responses = await axios.get(`https://backenddoctorchat.rouad-althaqa.com/api/chat/${user}`, {
        headers: {
            Authorization: `Bearer ${token}`, // Include the token in the header
        }        });
        setMessages(responses.data.chat);

        console.log(responses,'responses')
      const id=userData.userId
      const roomId = [id, user].sort().join("-");
      setRoomId(roomId)
      console.log(roomId,'roomId')
      socket.emit("joinPrivateRoom", { user1: id, user2: user });
    }
  
    toggleSidebar()
  setActiveUser(user)
}
let typingTimeout;

const onStartType = (user) => {
  socket.emit('StartType', { roomId, userTypeing: user });
};

const handleTyping = (user) => {
  if (typingTimeout) clearTimeout(typingTimeout);

  typingTimeout = setTimeout(() => {
    onStartType(user);
  }, 200);
};
const UploadImage = async (value) => {
  const file = value;
  if (!file) return;

  const formData = new FormData();
  formData.append("file", file);

  try {
    const response = await axios.post(
      "https://backenddoctorchat.rouad-althaqa.com/api/upload",
      formData,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "multipart/form-data",
        },
      }
    );

    console.log("Server response:", response.data);
    const { filePath } = response.data;

    const token = localStorage.getItem("token");
    const userData = decodeToken(token); // Handle decode errors
    const id = userData.userId;

    if (roomId && activeUser) {
      socket.emit("sendPrivateMessage", {
        roomId,
        sender: id,
        receiver: activeUser,
        type: "image",
        media: filePath,
        doctorId: activeUser,
        userId: id,
      });

      socket.emit("updateUser", { userId: activeUser });
      console.log("Image sent:", filePath);
    } else {
      console.error("Missing roomId or activeUser");
    }
  } catch (error) {
    console.error("Error uploading image:", error.response?.data || error.message);
  }
};

const handleTypeing=(data)=>{
  if(data.userTypeing=userId)
  {
    setTypeing(data.message)

  }
  console.log(data,userId,'typing')

}
const logoutUser=()=>{
  localStorage.removeItem('token')
  navigate('/')
}
useEffect(()=>{
  socket.on("typing", handleTypeing);

  // Cleanup the listener on component unmount to prevent duplicates
  return () => {
    socket.off("typing", handleTypeing);
  };
},[socket])
const toggleSidebar = () => {
  const sidebar = document.querySelector(".chat-sidebar");
  sidebar.classList.toggle("active");
};
  return (
    <div className="chat-container">
      {/* Sidebar */}
      <div className="chat-sidebar">
        <div className="ContainerUsers">
        <h2>Doctors</h2>
        <img src={Logout} width={50} height={50} onClick={logoutUser} style={{    cursor: 'pointer'}} />
        </div>
         <ul>
          {users.map((user) => (
            <li
              key={user.name}
              className={user.userId === activeUser ? "active-user" : ""}
              onClick={() => ChosesactiveUser(user.userId,user.name)}
            >
              {user.name}
            </li>
          ))}
        </ul>
      </div>

      {/* Chat Window */}
      <div className="chat-window">
        <h1>Chat with {activeUser} {type}</h1>
        <button className="sidebar-toggle" onClick={toggleSidebar}>☰</button>

        <div className="messages">
        <h1>  {messages.length ===0 ?`لا يوجد راسائل مع دكتور    ${Doctor} `   : ""}</h1>           {messages
            .filter((msg) => msg.receiver === activeUser ||  msg.sender === userId && msg.receiver === userId ||  msg.sender === activeUser )
            .map((msg, index) => (
              <div
                key={index}
                className={`message ${msg.sender === userId ? "user" : "bot"}`}
              >
                <strong   className={`message ${msg.sender === userId ? "textuser" : "textbot"}`} >{msg.sender}:</strong>{" "}
                {
  msg.type === "text" ? (
    <p className={`message ${msg.sender === userId ? "usertext" : "botext"}`}>
      {msg.media}
    </p>
  ) : msg.type === "voice" ? (
    <audio controls src={`https://backenddoctorchat.rouad-althaqa.com${msg.media}`}></audio>
  ) : msg.type === "image" ? (
    <img
      src={`https://backenddoctorchat.rouad-althaqa.com${msg.media}`}
      alt="Uploaded content"
      className="uploaded-image"
      width={350}
      height={350}
    />
  ) : (
    <p className="message unknown-type">Unsupported message type</p>
  )
}

              </div>
            ))}
              <div ref={messagesEndRef} />
        </div>
        <form className="input-container" onSubmit={handleSend}>
          <input
            type="text"
            value={currentMessage}
            onKeyUp={()=>onStartType(userId)}
            onChange={(e) => {
              handleTyping(userId);
              setCurrentMessage(e.target.value)}}
            placeholder="Type a message..."
          />
          <button type="button" onClick={startVoiceRecognition}>
            🎤
          </button>
          <button type="submit">Send</button>
          <button
            type="button"
            onClick={isRecording ? stopVoiceRecording : startVoiceRecording}
          >
            {isRecording ? "🛑 Stop" : "🎙️ Record"}
          </button>
          <button
  type="button"
  onClick={() => document.getElementById("imageUpload").click()}
  disabled={isUploading}
>
  {isUploading ? "Uploading..." : "📷 Upload Image"}
</button>
<input
  type="file"
  id="imageUpload"
  style={{ display: "none" }}
  accept="image/*"
  onChange={async (e) => {
    setIsUploading(true);
    await UploadImage(e.target.files[0]);
    setIsUploading(false);
  }}
/>
        </form>
      </div>
    </div>
  );
};

export default UserChat;

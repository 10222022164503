import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate, Link } from 'react-router-dom'; // Import Link for navigation
import { jwtDecode } from 'jwt-decode'; // Ensure you use "jwt-decode" correctly
import './css/Login.css'; // Import a CSS file for styling

const decodeToken = (token) => {
    try {
        const decoded = jwtDecode(token); // Decode the token
        console.log('Decoded Token:', decoded);
        return decoded;
    } catch (error) {
        console.error('Invalid token:', error.message);
        return null;
    }
};

function Login() {
  const [formData, setFormData] = useState({ userId: '', password: '' });
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('https://backenddoctorchat.rouad-althaqa.com/api/login', formData);
      const { token } = response.data;
      localStorage.setItem('token', token); // Store the token in localStorage
      const userData = decodeToken(token);
      const role = userData.role;
      console.log(role, 'role');
      alert('Login successful!');

      if (role === "all") {
        navigate('/main');
      } else if (role === "user") {
        navigate('/user');
      } else if (role === "admin") {
        navigate('/doctor');
      }
    } catch (err) {
      setError(err.response?.data?.message || 'An error occurred');
    }
  };

  return (
    <div className="body">
      <div className="login-container">
        <div className="login-card">
          <h2 className="login-title">Login</h2>
          {error && <p className="error-message">{error}</p>}
          <form onSubmit={handleSubmit} className="login-form">
            <input 
              className="login-input" 
              name="userId" 
              placeholder="User ID" 
              value={formData.userId} 
              onChange={handleChange} 
              required 
            />
            <input 
              className="login-input" 
              name="password" 
              type="password" 
              placeholder="Password" 
              value={formData.password} 
              onChange={handleChange} 
              required 
            />
            <button className="login-button" type="submit">Login</button>
          </form>
          <p className="register-link">
            Don't have an account? <Link to="/register">Register here</Link>.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Login;
  
import React, { useEffect, useState } from 'react';
import { OtpInput } from 'reactjs-otp-input';
import './css/Verify.css'; // Import the CSS file
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';

const Verify = () => {
  const [otp, setOtp] = useState('');
  const location = useLocation();
  const formData = location.state || null;
  const [error, setError] = useState('');
  const navigate = useNavigate();

  useEffect(()=>{
if(formData!=null){
  console.log(formData)
}else{
  console.log('null')

}
  },[])
  const handleChange = (otp) => {
    setOtp(otp);
    console.log('Entered OTP:', otp); // Debugging or API interaction
  };
  const handleOtp=async()=>{
    try {
      console.log({...formData,otp})

    const res=  await axios.post('https://backenddoctorchat.rouad-althaqa.com/api/verify-otp', {...formData,otp});
    navigate('/');

      console.log(res)
    } catch (err) {
      setError('An error occurred');
      console.log(err.response?.data?.message)
    }
  }

  return (
    <div className="container">
      <h1 className="title">Verify OTP</h1>
      <h1 className="title">{error}</h1>

      
      <p className="subtitle">Enter the 6-digit code sent to your phone.</p>
      
      <OtpInput
        value={otp}
        onChange={handleChange}
        numInputs={6}
        separator={<span className="separator">-</span>}
        inputStyle="input"
        focusStyle="input"
      />
      <button className="button" onClick={handleOtp}>
        Verify
      </button>
    </div>
  );
};

export default Verify;
